import React from 'react';
import './style.scss';
import Main from '../Main/index'
import Calculator from '../Calculator/index'

// import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';

function App() {
  const [cookies] = useCookies();
  // const { t, i18n } = useTranslation();
  // const changeLanguage = (language: any) => {
  //   i18n.changeLanguage(language);
  // };
  return (
    <div className="App">
    

      {/* <div className="selectLang">
        <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
          <option value="ru">RU</option>
          <option value="kz">KZ</option>
          <option value="en">EN</option>
        </select>
      </div> */}


      {!cookies.YouSpecialist ?
        <Main />
      : 
        <Calculator />
      }
    </div>
  );
}

export default App;

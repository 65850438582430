import './style.scss'

import classNames from 'classnames'
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';
export default function YouSpecialist({ className }: GlobalProps): JSX.Element {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['YouSpecialist']);
  return (
    <div className={classNames('Modal__wrapper Modal__warning', className)}>
      <div className="warning">{t('modal.main.YouSpecialist')}</div>
      {cookies ?
      <button onClick={() => setCookie('YouSpecialist', true)}><span>{t('buttons.Confirm')}</span></button>
      : null }
    </div>
  )
}

import './style.scss'

import classNames from 'classnames'
import { useTranslation } from "react-i18next";

import mail from '../../../assets/images/mail.svg';

export default function Information({ className }: GlobalProps): JSX.Element {
  const { i18n } = useTranslation();

  return (
    <div className={classNames('Modal__wrapper Modal__Information', className)}>
      <div className="container">
        <img src={mail} alt="" />
        {i18n.language === 'kz' ?
          <div className="Modal__Information-block">
            <div className="company">
              <p>Контактное лицо от имени <span style={{ whiteSpace: 'nowrap' }}>PTC Therapeutics</span> для принятия претензий, в том числе информации о нежелательных явлениях:</p>
              <p>Представительство АО «Ген Илач ве Саглык Урунлери Санаи ве Тиджарет» в Республике Казахстан г. Алматы 050042, ул. Жандосова, 98, БЦ Навои Тауэрс, офис 401</p>
              <p><b>Тел.:</b> 8 (727) 391 10 97, 8 (727) 243 47 77<br/> <b>тел./факс:</b> 8 (727) 391 10 97 (внут. 101)</p>
            </div>
            <p className="mail">Эл. почта: <a target="_blank" rel="noreferrer" href="mailto:infokz@genilac.com">infokz@genilac.com</a></p>
            <p className="small">Информация предназначена только для специалистов здравоохранения. Не для распространения. Носит исключительно научно-информационный характер. Не является рекламой.</p>
            <p className="small">KZ-TRNS-0203 Апрель 2022.</p>
          </div>
        :
          <div className="Modal__Information-block">
            <div className="company">
              <p>Контактное лицо от имени <span style={{ whiteSpace: 'nowrap' }}>PTC Therapeutics</span> для принятия претензий, в том числе информации о нежелательных явлениях:</p>
              <p><b>ООО «Биомапас», Россия, 105064,<br/> г. Москва, ул. Земляной вал, д. 9, эт. 4 пом. II, комн. 84.</b></p>
              <p><b>Тел: +7 499 500 56 93</b></p>
            </div>
            <p>Бесплатный телефон службы медицинской информации <span style={{ whiteSpace: 'nowrap' }}>PTC Therapeutics:</span> <b>8 800 551 03 97</b></p>
            <p className="mail">Эл. почта: <a target="_blank" rel="noreferrer" href="mailto:medinfo@ptcbio.com">medinfo@ptcbio.com</a></p>
            <p className="small">ООО «ПиТиСи Терапьютикс» 123112, Москва, Пресненская Набережная, 12, этаж 46, офис 1</p>
            <p className="small">RU-TRNS-0215</p>
          </div>
        }
      </div>
    </div>
  )
}

import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
	.use(Backend)
	.use(LanguageDetector)
  	.use(initReactI18next)
	.init({
		debug: false,
		//lng: 'en',
		// fallbackLng: 'kz',
		fallbackLng: 'ru',
		saveMissing: false,
		detection: {
			order: ['cookie'],
		    caches: ['cookie'],
		    lookupCookie: 'i18next',
		},
		interpolation: {
			escapeValue: false
		},
	}, function(err, t) {
		// console.log(i18n.t('title'))
	}).then(function(t) {
		// const [cookies, setCookie, removeCookie] = useCookies();
		// i18n.changeLanguage('ru')
		// console.log(i18n.language)
	});

export default i18n;
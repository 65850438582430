import React, {useState} from 'react';
import classNames from 'classnames'

import { Trans, useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';


import './style.scss';
import logo from '../../assets/images/logo.svg';
import logoTM from '../../assets/images/logo-tm.svg';
import calculator from '../../assets/images/calculator.svg';
import Modal from './../Modal';
import YouSpecialist from './../Modal/YouSpecialist';

export default function App(): JSX.Element {
  const [popup, setPopup] = useState(true)
  const [cookies] = useCookies(['YouSpecialist']);
  const { t, i18n } = useTranslation();
  function popupState(e: any) {
    setPopup(e)
  }

  return (
    <div className="Main">
      <div className="center__block">
        <div className="center__block_image"><img src={calculator} alt="" /></div>
        <h1 className="center__block_title"><Trans components={[<sup></sup>]} values={{ register: '®'}}>{t('title')}</Trans></h1>
        <div className="center__block_logo">
          <img src={i18n.language !== 'kz' ? logo : logoTM} alt="" />
          <div className="Main_information">{t('warn')}</div>
        </div>
      </div>
      {!cookies.YouSpecialist ?
        <Modal className={classNames('YouSpecialist')} delay={2000} open={popup} popupState={popupState}><YouSpecialist /></Modal>
      : null}
    </div>
  );
}
import React, {useState} from 'react';
import classNames from 'classnames'
import './style.scss';
import close from '../../assets/images/close.svg';
import {Animated} from "react-animated-css";

interface Props extends GlobalProps {
  delay: number
  open: boolean
  popupState: (e: any) => void
}

export default function Modal({children, className, delay, popupState, open}: Props): JSX.Element {
  const [popup, setPopup] = useState(open)
  function closePopup(e: any) {
    setPopup(e)
    popupState('')
  }
  function closeOut(event: any) {
    const withinBoundaries = event.nativeEvent.composedPath().includes(document.querySelector('.Modal__content'));
    if ( ! withinBoundaries ) {
      setPopup(false)
      popupState('')
    }
  }
  if(popup) {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
  } else {
    document.getElementsByTagName('body')[0].style.overflow = 'auto'
  }
  return (
    <Animated className="Modal"
    animationIn="fadeIn"
    animationOut="fadeOut"
    animationInDuration={400}
    animationOutDuration={400}
    animationInDelay={delay}
    animationOutDelay={400}
    isVisible={popup}>
      <dialog className={classNames('Modal', className)} onClick={closeOut}>
        <Animated className="Modal__content"
          animationIn="bounceInUp"
          animationOut="bounceOutDown"
          animationInDuration={800}
          animationOutDuration={800}
          animationInDelay={delay + 200}
          animationOutDelay={0}
          isVisible={popup}>
          <article className="Modal__content">
            <div className="Modal__close" onClick={() => closePopup(false)}><img src={close} alt="" /></div>
            {children}
          </article>
        </Animated>
      </dialog>
    </Animated>
  );
}

import React, {useState} from 'react';
import classNames from 'classnames'
import { Trans, useTranslation } from "react-i18next";

import SelectSearch from 'react-select-search';
import {Animated} from "react-animated-css";

import Modal from './../Modal';
import Instruction from './../Modal/Instruction';
import Information from './../Modal/Information';

import './style.scss';
import close from '../../assets/images/close.svg';


export default function App(): JSX.Element {
  const [calcComplited, setCalcComplited] = useState(false);
  const [calcEsklp, setCalcEsklp] = useState(false);
  const [popup, setPopup] = useState('')
  const { t, i18n } = useTranslation();

  const data = [
    {
      "weight": "12 — 14",
      "packs": [
        {
          "pack": 125,
          "doze": [1, 1, 0]
        },
        {
          "pack": 250,
          "doze": [0, 0, 1]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "15 — 16",
      "packs": [
        {
          "pack": 125,
          "doze": [1, 1, 1]
        },
        {
          "pack": 250,
          "doze": [0, 0, 1]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "17 — 20",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [1, 1, 1]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "21 — 23",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 1]
        },
        {
          "pack": 250,
          "doze": [1, 1, 1]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "24 — 26",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [1, 1, 2]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "27 — 31",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 1]
        },
        {
          "pack": 250,
          "doze": [1, 1, 2]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "32 — 35",
      "packs": [
        {
          "pack": 125,
          "doze": [1, 1, 1]
        },
        {
          "pack": 250,
          "doze": [1, 1, 2]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "36 — 39",
      "packs": [
        {
          "pack": 125,
          "doze": [1, 1, 0]
        },
        {
          "pack": 250,
          "doze": [1, 1, 3]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "40 — 44",
      "packs": [
        {
          "pack": 125,
          "doze": [1, 1, 1]
        },
        {
          "pack": 250,
          "doze": [1, 1, 3]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "45 — 46",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 1]
        },
        {
          "pack": 250,
          "doze": [2, 2, 3]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 0]
        }
      ]
    },
    {
      "weight": "47 — 55",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [2, 2, 0]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 1]
        }
      ]
    },
    {
      "weight": "56 — 62",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [2, 2, 1]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 1]
        }
      ]
    },
    {
      "weight": "63 — 69",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [3, 3, 1]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 1]
        }
      ]
    },
    {
      "weight": "70 — 78",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [3, 3, 2]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 1]
        }
      ]
    },
    {
      "weight": "79 — 86",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [3, 3, 3]
        },
        {
          "pack": 1000,
          "doze": [0, 0, 1]
        }
      ]
    },
    {
      "weight": "87 — 93",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [0, 0, 3]
        },
        {
          "pack": 1000,
          "doze": [1, 1, 1]
        }
      ]
    },
    {
      "weight": "94 — 105",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [0, 0, 0]
        },
        {
          "pack": 1000,
          "doze": [1, 1, 2]
        }
      ]
    },
    {
      "weight": "106 — 111",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [0, 0, 1]
        },
        {
          "pack": 1000,
          "doze": [1, 1, 2]
        }
      ]
    },
    {
      "weight": "112 — 118",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [1, 1, 1]
        },
        {
          "pack": 1000,
          "doze": [1, 1, 2]
        }
      ]
    },
    {
      "weight": "119 — 125",
      "packs": [
        {
          "pack": 125,
          "doze": [0, 0, 0]
        },
        {
          "pack": 250,
          "doze": [1, 1, 2]
        },
        {
          "pack": 1000,
          "doze": [1, 1, 2]
        }
      ]
    }
  ]

  const weightsObj = data.map((weight, index) => {
    return ({
      value: weight.weight,
      name: weight.weight + ' ' + t('calculator.values.weight')
    })
  })


  function prettyDate(num: number) {
    const monthesPostfixes = [t('calculator.values.period.period-1'), t('calculator.values.period.period-2'), t('calculator.values.period.period-3')];
    const monthes = num % 12;

    function postfix(num: any, postfixes: any)
      {
        num = num % 100;
        if (num > 19) {
          num = num % 10;
        }
        switch (num) {
          case 1:
            return postfixes[0];

          case 2: case 3: case 4:
            return postfixes[1];

          default:
            return postfixes[2];
        }
      }
      return num + ' ' + postfix(monthes, monthesPostfixes);
  }

  const periods = 12
  const periodsObj = [];
  for (let i = 1; i <= periods; i++) {
    periodsObj.push({
      value: i,
      name: prettyDate(i)
    });
  }
  // const selectChangeWeight = (event: any) => {
  //   setWeightState(event)
  // };
  // const selectChangePeriod = (event: number) => {
  //   setPeriodState(event)
  // };
  // console.log(weightState, periodtState)

  interface Item {
    doze: number,
    pack: number,
    esklp: any
  }

  const [list, setList] = useState<Item[]>([]);

  function esklpDoze(pack: number) {
    const esklp = [15, 30, 120]
    switch(pack) {
      case 125:
        return esklp[0]
      case 250:
        return esklp[1]
      case 1000:
        return esklp[2]
    }
  }

  function onChange(event: any) {
    setList((prevList) => [])
    const reducer = (previousValue:any, currentValue:any) => previousValue + currentValue;
    event.preventDefault();

    data.map((weight, index) => {
      if(weight.weight === weightState) {
        for(let i=0; i<weight.packs.length; i++) {
          if(weight.packs[i].doze.some((elem: any) => elem > 0)) {
            setList((prevList) => [...prevList, {
              doze: weight.packs[i].pack,
              pack: weight.packs[i].doze.reduce(reducer),
              esklp: esklpDoze(weight.packs[i].pack)
            }])
          }
        }
        creatShemList(weight.packs)
      }
      return false;
    })
    setCalcComplited(true);
  }
  if(calcComplited) {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
  } else {
    document.getElementsByTagName('body')[0].style.overflow = 'auto'
  }
  interface Item2 {
    morning: any,
    day: any,
    evening: any
  }
  const [shemList, setShemList] = useState<Item2[]>([]);

  function creatShemList(packs: any) {
    setShemList((prevList) => []);
    const morning:any[] = [];
    const day:any[] = [];
    const evening:any[] = [];
    packs.map((item: any, index: any) => {
      if(item.doze.some((elem: any) => elem > 0)) {
        for(let i=0; i<item.doze.length; i++) {
          if(item.doze[i] > 0) {
            switch(i) {
              case 0:
                morning.push([item.pack, item.doze[i]])
              break
              case 1:
                day.push([item.pack, item.doze[i]])
              break
              case 2:
                evening.push([item.pack, item.doze[i]])
              break
            }
          }
        }
      }
      return false;
    })
    setShemList((prevList) => [...prevList, {
      morning: morning,
      day: day,
      evening: evening
    }])
  }
  function popupState(e: any) {
    setPopup(e)
  }

  const [weightState, setWeightState] = useState('');
  const [periodtState, setPeriodState] = useState('');

  function closeOut(event: any) {
    const withinBoundaries = event.nativeEvent.composedPath().includes(document.querySelector('.Modal__content'));
    if ( ! withinBoundaries ) {
      setCalcEsklp(false)
    }
  }

  // console.log(periodtState)
  let url="#";
  return (
    <div className="Calculator">
      <div className="Calculator_form">
        <div className="container">
          <form>
            <div>
              <span>{t('calculator.form.category')}</span>
              <SelectSearch
                options={weightsObj}
                key="category"
                value={weightState}
                onChange={(value: any) => {
                  setWeightState(value)
                }}
                placeholder={t('calculator.values.weight')}
              />
            </div>
            <div>
              <span>{t('calculator.form.period')}</span>
              <SelectSearch options={periodsObj}
                key="period" value={periodtState}
                onChange={(value: any) => {
                  setPeriodState(value)
                }}
                placeholder={t('calculator.values.period.period-2')} />
            </div>
            <button onClick={onChange} disabled = {!weightState || !periodtState ? true : false}><span>{t('buttons.Calculate')}</span></button>
          </form>
          <div className="Calculator_warning"><div><span>Внимание:</span> дозировку необходимо пересчитать при переходе пациента в следующую весовую категорию</div></div>
          <div className="footerNote">
            <p className="Calculator_warning_children"><Trans components={{ sup: <sup /> }}>{t('footernote.calculator_warning_children')}</Trans></p>
            <div className="link_button"><a href={url} onClick={(e) => {e.preventDefault(); setPopup('Instruction')}}><Trans components={{ u: <u />, sup: <sup /> }}>{t('footernote.instruction_link')}</Trans></a></div>
            <div className="disclaimer">
              <p><Trans components={{ Link: <a /> }}>{t('footernote.contacts')}</Trans></p>
              <p><u>{t('footernote.note_1')}</u></p>
              <p><u>{t('footernote.note_2')}</u></p>
            </div>
          </div>
        </div>
      </div>
      {calcComplited ?
        <Animated className="Calculator_results"
          animationIn="slideInUp"
          animationOut="slideOutDown"
          animationInDuration={500}
          animationOutDuration={500}
          animationInDelay={0}
          animationOutDelay={0}
          isVisible={calcComplited}>
          <dialog className="Calculator_results">
            <article className="container">
              <div className="Modal__close" onClick={() => setCalcComplited(false)}><img src={close} alt="" /></div>
              <div className="Calculator_results_content">
                <div className="Calculator_results_title">Количество упаковок на <b style={{ whiteSpace: 'nowrap' }}>{prettyDate(parseInt(periodtState))}</b></div>
                <div className="Calculator_results_title-sub">Категория веса: <b style={{ whiteSpace: 'nowrap' }}>{weightState} {t('calculator.values.weight')}</b></div>
                <div className="Calculator_results_title-doze">1 упаковка содержит 30 саше</div>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>Дозировка</th>
                        <th>Упаковки</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(({ doze, pack}) => (
                        <tr key={doze}>
                          <td>{doze} {t('calculator.values.dosage.mg')}</td>
                          <td>{pack * parseInt(periodtState)} {t('calculator.values.quantity')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {i18n.language != 'kz' ?
                <button className="gray" onClick={() => setCalcEsklp(true)}><span>Пересчитать для ЕСКЛП</span></button>
                : null}
                <div className="Calculator_results_title">Схема приема (саше/ в сутки):</div>
                {shemList.length ?
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Дозировка</th>
                          <th>Кол-во саше</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><b>{t('calculator.interval.morning')}</b></td>
                          <td>
                            {shemList[0].morning.map((item:any, index:number) => (
                              <span key={'morning-weight' + index}>{item[0]} {t('calculator.values.dosage.mg')}</span>
                            ))}
                          </td>
                          <td>
                            {shemList[0].morning.map((item:any, index:number) => (
                              <span key={'morning-pack' + index}>{item[1]} {t('calculator.values.quantity')}</span>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td><b>{t('calculator.interval.day')}</b></td>
                          <td>
                            {shemList[0].day.map((item:any, index:number) => (
                              <span key={'day-weight'+index}>{item[0]} {t('calculator.values.dosage.mg')}</span>
                            ))}
                          </td>
                          <td>
                            {shemList[0].day.map((item:any, index:number) => (
                              <span key={'day-pack'+index}>{item[1]} {t('calculator.values.quantity')}</span>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td><b>{t('calculator.interval.evening')}</b></td>
                          <td>
                            {shemList[0].evening.map((item:any, index:number) => (
                              <span key={'evening-weight'+index}>{item[0]} {t('calculator.values.dosage.mg')}</span>
                            ))}
                          </td>
                          <td>
                            {shemList[0].evening.map((item:any, index:number) => (
                              <span key={'evening-pack'+index}>{item[1]} {t('calculator.values.quantity')}</span>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                : null }
                <div className="link_button"><a href={url} onClick={(e) => {e.preventDefault(); setPopup('Instruction')}}><u><Trans components={{ u: <u />, sup: <sup /> }}>{t('footernote.instruction_small_link')}</Trans></u></a></div>
                <div className="link_button"><a href={url} onClick={(e) => {e.preventDefault(); setPopup('Information')}}><u>Важная информация</u></a></div>
                <div className="disclaimer">
                  <p><Trans components={{ Link: <a /> }}>{t('footernote.contacts')}</Trans></p>
                  <p><u>{t('footernote.note_1')}</u></p>
                  <p><u>{t('footernote.note_2')}</u></p>
                </div>
              </div>
            </article>
          </dialog>
        </Animated>
      : null }
      {calcEsklp ?
        <Animated className="Modal"
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={400}
        animationOutDuration={400}
        animationInDelay={0}
        animationOutDelay={400}
        isVisible={calcEsklp}>
        <dialog className='Modal' onClick={closeOut}>
          <Animated className="Modal__content"
            animationIn="bounceInUp"
            animationOut="bounceOutDown"
            animationInDuration={800}
            animationOutDuration={800}
            animationInDelay={200}
            animationOutDelay={0}
            isVisible={calcEsklp}>
            <article className="Modal__content">
              <div className="Modal__close" onClick={() => setCalcEsklp(false)}><img src={close} alt="" /></div>
              <div className="Calculator_results_esklp">
                <div className="container">
                  <div className="Calculator_results_esklp_title">Пересчет в граммы для соответствия ЕСКЛП*<br/> <b style={{ whiteSpace: 'nowrap' }}>на {prettyDate(parseInt(periodtState))}</b></div>
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th>Дозировка</th>
                          <th>Граммы</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map(({ doze, pack, esklp }) => (
                          <tr key={doze}>
                            <td>{doze} {t('calculator.values.dosage.mg')}</td>
                            <td>{pack * parseInt(periodtState) * esklp} {t('calculator.values.dosage.g')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="Calculator_results_esklp_footnotes">
                    <p>*ЕСКЛП – Единый структурированный справочник – каталог лекарственных препаратов</p>
                    <p>Упаковка 125 мг (№30) = 15 грамм<br/>
                    Упаковка 250 мг (№30) = 30 грамм<br/>
                    Упаковка 1000 мг (№30) = 120 грамм</p>
                  </div>
                </div>
              </div>
            </article>
          </Animated>
        </dialog>
        </Animated>
      : null}

      {popup && (
        <Modal popupState={popupState} delay={0} open={true} className={classNames({popup})}>
            {popup === 'Instruction' && <Instruction />}
            {popup === 'Information' && <Information />}
        </Modal>
      )}
    </div>
  );
}
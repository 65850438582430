import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
// import {Helmet, HelmetProvider} from 'react-helmet-async';

import App from './components/App';
import './assets/scss/index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './i18n';

// const app = (
//   <HelmetProvider>
//     <Helmet htmlAttributes={{ lang : 'ru' }}>
//       <title>Калькулятор</title>
//     </Helmet>
//     <Suspense fallback={<div className="loadingApp"></div>}>
//       <App />
//     </Suspense>
//   </HelmetProvider>
// );

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div className="loadingApp"></div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

//  <React.StrictMode>
//    <Helmet htmlAttributes={{ lang : 'ru' }}>
//        <title>Калькулятор</title>
//    </Helmet>
//    <Suspense fallback={<div className="loadingApp"></div>}>
//      <App />
//    </Suspense>
//  </React.StrictMode>,

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
